* {
  box-sizing: border-box;
}

:root {
  margin: 0;
  padding: 0;
  /* min-width: 320px; */
  /* height: 100%; */
}

body {
  font-family: "Playfair Display", Helvetica, Arial, sans-serif,
    "Playfair Display" !important;
  min-height: 100vh;
}

.app-wrapper {
  display: grid;
  grid-template-rows: auto 1fr auto;
  background: #fcf6f5ff;
  color: #89abe3ff;
}

.link:hover {
  text-decoration: underline;
}
@media all and (max-width: 767px) {
  .loginOutBtn {
    background: #fcf6f5ff;
    color: #89abe3ff;
    font-weight: 800;
    box-shadow: "0 0.2rem 0.75rem rgba(0, 0, 0, 0.3)";
    margin-top: 1rem;
    margin-left: 0rem !important;
    width: 25%;
  }
}
@media all and (max-width: 655px) {
  .page-wrapper {
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    padding: 2rem;

    min-height: 101vh;
    margin-top: 5rem;
    justify-content: flex-start;
    align-items: center;
    z-index: 2;
  }

  .home-title {
    font-size: 3.5rem;
    font-weight: 900;
  }

  .homeImgsWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-around; */
    /* width: 100%; */
    margin-top: "5rem";
  }
  .homeImg {
    margin-top: "2rem";
  }
}

@media all and (min-width: 656px) {
  .page-wrapper {
    display: flex;
    flex-direction: column;
    /* place-items: center; */
    padding: 2rem;
    /* margin-bottom: 2rem; */
    /* height: 100%; */
    min-height: 101vh;
    margin-top: 5rem;
    justify-content: flex-start;
    align-items: center;
    z-index: 2;
  }
  .loginOutBtn {
    background: #fcf6f5ff;
    color: #89abe3ff;
    font-weight: 800;
    box-shadow: "0 0.2rem 0.75rem rgba(0, 0, 0, 0.3)";
    margin-left: "1rem";
  }

  .home-title {
    font-size: 4rem;
    font-weight: 900;
  }

  .homeImgsWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    width: 100%;
    margin-top: "5rem";
  }
}

.home-title-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 0.75rem;
  font-family: "Lobster";
}

/* Create Account */
.form {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}
.submit-btn {
  place-items: center;
  margin-top: 1.5rem;
}

#red {
  color: red;
}
#green {
  color: green;
}

.transaction {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-grow: 1;
  border-bottom: 1px solid black;
  padding: 2rem;
  font-family: monospace;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;

  background-color: white;
  padding: 6rem;
  border-radius: 5px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 50;
}

@media all and (max-width: 655px) {
  .card-denied {
    z-index: 50;
    box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
    display: flex;
    margin: 1rem;
    justify-content: center;
    align-content: center;
  }
  .denied-btns {
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .denied-btn-1 {
    width: 50%;
    padding: 1rem;
    margin-bottom: 1rem;
  }
  .denied-btn-2 {
    width: 50%;
    padding: 1rem;
  }
}
@media all and (max-width: 370px) {
  .denied-btn-1 {
    font-size: 0.75em;
    width: 60%;
    padding: 1rem;
    margin-bottom: 1rem;
  }
  .denied-btn-2 {
    width: 60%;
    padding: 1rem;
  }
}
@media all and (min-width: 656px) {
  .card-denied {
    z-index: 50;
    box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
    height: 20rem;
  }
  .denied-btns {
    justify-content: center;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .denied-btn-1 {
    margin-right: 1rem;
    width: 40%;
    padding: 1rem;
  }
  .denied-btn-2 {
    width: 40%;
    padding: 1rem;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  z-index: 4;
}

.green {
  color: "green";
}

/* Footer */
.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #89abe3ff;
  /* min-height: 8vh; */
  padding: 0.1rem;
  padding-bottom: 0.25rem;
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  /* margin-top: 5rem; */
  z-index: 1;
}

#dropdown-autoclose-true::after {
  display: none;
}

#navLogOutBtn:active {
  background: none;
}
#navDeleteBtn:active {
  background: none;
}
